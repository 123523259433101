import React, { useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import { ReactComponent as CameraIcon } from '../../../../../../assets/icons/camera_icon.svg';
import { ReactComponent as BinIcon } from '../../../../../../assets/icons/bin_icon.svg';
import './dropzone.scss';

const MAX_FILE_SIZE = 4096052;
const ERRORS_LIST = {
  bigSize: 'The maximum size of photo should not exceed 4 MB',
  maxFiles: 'You should upload only one photo',
};

const PlanDropzone = ({
  errors,
  index,
  uploadedFiles,
  imageCount,
  setImageCount,
  setUploadedFiles,
  setErrors,
}) => {
  const [id, setId] = useState(0);
  const dropzoneRef = useRef(null);

  React.useEffect(() => {
    if (Object.keys(errors).length) {
      removeImage(0);
    }
  }, [errors]);

  const removeImage = (id) => {
    setUploadedFiles(uploadedFiles.filter((file) => file.id !== id));
    setImageCount((imageCount) => imageCount - 1);
  };

  const onDrop = (acceptedFiles) => {
    setErrors({});

    const bigSizeFile = acceptedFiles.find((file) => file.size > MAX_FILE_SIZE);

    if (bigSizeFile) {
      return setErrors({
        [ERRORS_LIST.bigSize]: [ERRORS_LIST.bigSize],
      });
    }

    if (acceptedFiles.length > 1) {
      return setErrors({
        [ERRORS_LIST.maxFiles]: [ERRORS_LIST.maxFiles],
      });
    }

    setUploadedFiles([
      ...uploadedFiles,
      ...acceptedFiles.map((file, index) => Object.assign(file, {
        preview: URL.createObjectURL(file),
        id: id + index + 1,
      })),
    ]);
    setId(id + acceptedFiles.length);
    setImageCount(imageCount + acceptedFiles.length);
  };

  const onClick = (e) => {
    e.stopPropagation();
    removeImage(uploadedFiles[index].id);
  };

  const isDisabled = imageCount > 0;

  return (
    <Dropzone
      ref={dropzoneRef}
      accept="image/jpeg, image/jpg"
      disabled={isDisabled}
      onDrop={onDrop}
      noKeyboard
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div
          {...getRootProps()}
          className={`dropzone-container${isDragActive ? ' is-active' : ''}`}
        >
          <input {...getInputProps()} />
          <div className="dropzone-icon">
            <CameraIcon />
          </div>
          {imageCount > index && (
            <>
              <div
                className="preview-image"
                style={{ backgroundImage: `url(${uploadedFiles[index].preview})` }}
              />
              <div className="preview-bin" onClick={onClick}>
                <BinIcon />
              </div>
            </>
          )}
        </div>
      )}
    </Dropzone>
  );
};

PlanDropzone.propTypes = {
  index: PropTypes.number.isRequired,
  uploadedFiles: PropTypes.array.isRequired,
  imageCount: PropTypes.number.isRequired,
  setImageCount: PropTypes.func.isRequired,
  setUploadedFiles: PropTypes.func.isRequired,
};

export default PlanDropzone;
