import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import ModalWindow from 'components/molecules/modal-window';
import './invite-friends-modal.scss';
import PlanContent from '../welcome/basic/plan-content';
import api from '../../../utils/api';
import useHttpClient from '../../../utils/hooks/http-request';
import TextInput from '../../atoms/text-input';
import useGlobalState from '../../../utils/global-state';

const UploadPhotosModal = ({
 onSpecialConnectionClick, isModalOpened, closeModal, text
}) => {
  const { sendRequest, isLoading } = useHttpClient();
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [errors, setErrors] = useState({});
  const [fullName, setFullName] = useState('');
  const [{ userData }] = useGlobalState();

  const submitImages = useCallback(async (uploadedFiles) => {
    if (!uploadedFiles.length) return;

    const postData = new FormData();
    uploadedFiles.forEach((file, index) => postData.append(`file[${index}]`, file));
    postData.append('full_name', fullName);

    try {
      setIsLoadingButton(true);
      const response = await sendRequest(api.storePortrait(), 'POST', postData);

      if (response) {
        setTimeout(() => {
          setIsLoadingButton(false);
          closeModal();
          onSpecialConnectionClick(response?.uuid);
        }, 5000);
      }
    } catch (error) {
      setIsLoadingButton(false);
      setErrors(error.response?.data);
    }
  }, [fullName]);

  const onChange = (e) => {
    setFullName(e.target.value);
  };

  return (
      <ModalWindow
          isOpened={isModalOpened}
          close={closeModal}
          heading={text ? '' : 'Upload your friends photos'}
      >
        <TextInput
          name="full_name"
          label="Full name"
          placeholder="Enter full name"
          onChange={onChange}
          value={fullName}
          error={errors?.name || ''}
        />
        <PlanContent
            submitImages={submitImages}
            isLoading={isLoadingButton}
            errors={errors}
            setErrors={setErrors}
            needTitle={false}
            fullName={fullName}
        />
      </ModalWindow>
  );
}

UploadPhotosModal.propTypes = {
  isModalOpened: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default UploadPhotosModal;
